@import "../../components/variables";

$md: "768px";
$sm: "680px";
$xs: "468px";

html,body{
    height: 11%;
}

#waiver .container{
    margin-top: auto;
    margin-left: auto;
    margin-bottom: auto;
    margin-right: auto;
    max-width: 1200px;

}

#waiver {
    background-color: #ECECEC;
    min-height: 100vh;
    max-height: 100%;
    .table-borderless {
        width: 100%;
        letter-spacing: 0.7px;

        td {
            padding: 0;
            margin: 0;
        }
    }

    .card {
        z-index: 0;
        border: none;
        border-radius: 0.5rem;
        position: relative;

        .card-body {
            -webkit-box-flex: 1;
            -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
            padding: 1.25rem;
            font-size: 13px;
        }

        .main-title-txt {
            font-size: 1.75rem;
            color: $base-color;
        }

        .main-card-title {
            color: $base-color;
            font-weight: bold;
        }

        .fs-subtitle {
            color: $base-color;
        }
    }

    .title-content {
        margin-bottom: 50px;
        color: $base-color;

        h2 {
            font-size: 1.75rem;
        }
    
        h4 {
            font-size: 1.3rem;
        }
        
        h6 {
            font-size: 0.8rem;
        }
    }

    .booking-info {
        align-items: center; 
        display: flex;
        flex-direction: column;
    }

    .booking-card-portal {
        max-height: auto;
        border: 1px solid $base-color;

    }

    // .booking-card-portal-collapse {
    //     max-height: 100%;
    //     transition: max-height 0.25s ease-out;
    //     border: 1px solid $base-color;
    // }

    .waiver-content {
        background-color: white;
        padding-bottom: 20px;
        border-radius: 8px;
        @media only screen and (max-width: $md) {
            padding-top: 16px;
        }
    }

    .form-card {
        background: white;
        border: 1px solid var(--color1);
        border-radius: 5px;
        padding: 20px 40px 30px 40px;
        box-sizing: border-box;
        width: 94%;
        margin: 0 3% 20px 3%;
        position: relative;
        @media only screen and (max-width: $md) {
            padding: 16px;
        }
        @media only screen and (max-width: $sm) {
        }
        @media only screen and (max-width: $xs) {
        }
    }
    
    .signing-button {
        width: 550px;
        background: var(--color1);
        font-weight: bold;
        color: white;
        border: 0 none;
        border-radius: 15px;
        cursor: pointer;
        padding: 20px 5px;
        margin: 20px auto;
        @media only screen and (max-width: $md) {
            width: 90%;
        }
    }

    input {
        background-color: re;
        padding: 0px 8px 4px 0px;
        border: none;
        border-bottom: 1px solid #ccc;
        border-radius: 0px;
        margin-bottom: 27px;
        margin-top: 2px;
        width: 100%;
        box-sizing: border-box;
        color: #000000;
        font-size: 16px;
        letter-spacing: 1px;
    }

    #progress{
        color: white;
        background-color: white;
        margin-bottom: 50px;
        border-radius: 8px;
        overflow: hidden;
        padding-top: 25px;
        @media only screen and (max-width: $md) {
            padding-top: 40px;
        }
        @media only screen and (max-width: 560px) {
            padding-bottom: 10px;
        }
    }

    #progress span{
        background: #FF6600;
        padding-left: 12px;
    }

    #progress .progress-line{
        display:flex;
    }

    #progress .progress-title{
        color: $lighter-green;
        font-size: 12px;
        font-weight: bold;
        @media only screen and (max-width: $md) {
            position: absolute;
            top: -18px;
            text-align: center;
        }
        @media only screen and (max-width: $sm) {
            top: -30px;
        }
        @media only screen and (max-width: $xs) {
            font-size: 10px;
        }
    }

    #progress .progress-item{
        display:flex; flex-direction: column; align-items: center;
        width: 20%;
        position: relative;
    }

    #progress .progress-voucher .progress-item{
        width: 25%;
    }

    #progress .progress-item .circle{
        position: relative;
        z-index: 2;
        color: #424b5a;
        border: 1px solid #424b5a;
        background: linear-gradient(to left, white 50%, $gray 50%) right;
        background-size: 202%;
        transition: 0.3s ease-in;
        background-color: white;
    }
    
    #progress .progress-item.finished .circle{
        // background: $gray;
        border: 1px solid $gray;
        background-position: left;
        color: white;
    }

    #progress .progress-item .circle-line{
        background: #424b5a;
        width: 102%;
        display: block;
        height: 6px;
        position: absolute;
        bottom: 15px;
        z-index: 1;
        
        background: linear-gradient(to left, #424b5a 50%, $gray 50%) right;
        background-size: 202%;
        transition: 0.5s ease-out;
        
        @media only screen and (max-width: 560px) {
            bottom: 9px;
        }
    }
    
    #progress .progress-item.finished .circle-line{
        background-position: left;
    }

    #progress .progress-item.ongoing .circle{
        color: #424b5a;
        border: 1px solid #424b5a;
        background-color: white;
    }

    #progress .progress-item.ongoing .circle-line{
        background: #424b5a;
    }

    #progress .progress-item.ongoing .progress-title{
        color:$lighter-green;
    }
    
    #progress .progress-item.finished .progress-title{
        color: $gray;
    }
    .fs-label {
        font-family: "Hedvig Letters Sans", sans-serif;
        color: $base-color;
        font-weight: 700;
    }
    .waiver-personal-info {
        letter-spacing: 0px;
        font-size: 14px;
    }

    .circle {
        cursor: pointer;
    }
    
    .action-button-previous {
        width: 100px;
        background: transparent;
        font-weight: bold;
        color: var(--color2);
        border: 0 none;
        border-radius: 10px;
        cursor: pointer;
        padding: 10px 10px;
        margin: 10px 35px;
    }

    .action-button {
        width: 100px;
        background: var(--color1);
        font-weight: bold;
        color: white;
        border: 0 none;
        border-radius: 10px;
        cursor: pointer;
        padding: 10px 10px;
        margin: 10px 35px;
    }
    
    .checkbox-container {
        display: block;
        position: relative;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 14px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        @media only screen and (max-width: $sm) {
            padding-left: 0px;
        }
    }

    .form-check-inline .form-check-input {
        position: static;
        margin-top: 0;
        margin-right: 0.3125rem;
        margin-left: 0;
        @media only screen and (max-width: $sm) {
            margin-left: 0!important;
        }
    }
    
    .form-check-input {
        position: absolute;
        margin-top: 0.3rem;
        margin-left: -1.25rem;
    }
    .fs-subtitle {
        color: $base-color;
    }
    .radio-container {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 14px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    .checkmark {
        position: absolute;
        top: -7px;
        left: 0;
        height: 16px;
        width: 16px;
        background-color: #eeeeee;
        border-radius: 50%;
        margin-left: 15px;
        border-style: solid;
        border-width: 1px;
    }
    .form-control {
        display: block;
        height: calc(2.0625rem + 2px);
        line-height: 1.5;
        background-color: #FFFFFF;
        background-clip: padding-box;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
    .form-control:focus {
        box-shadow: 0 1.5px 0px 0px $base-color;
    }
    .btn-submit-minor {
        width: 150px !important;
        border: 1px solid !important;
        margin-left: -0.25rem !important;
    }
    @media (min-width: 992px){
        .table-container-minor {
            margin-left: unset;
            justify-content: center;
            font-size: 14px;
        }
    }

    .table-container-minor {
        margin-bottom: 10px;
        margin-left: -0.6rem;
        justify-content: center;
        font-size: 14px;
    }

    .table-minor {
        background-color: var(--color1);
        color: white;
        th {
            padding: 8px;
            vertical-align: bottom;
        }
    }
    .btn-edit-minor {
        padding: 0.375rem 0.5rem;
        color: var(--color2);
    }
    .btn-link {
        font-weight: 400;
        color: #00A09D;
        background-color: transparent;
    }
    .table-content {
        width: auto;
        font-size: 14px;
    }
    .signing-btn {
        margin-top: 10px;
        width: 75%;
        // display: none;
        background-color: var(--color1);
        border-color: var(--color1);
        border: 1px solid;
    }
    .btn {
        display: inline-block;
        font-weight: 400;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        user-select: none;
        border: 1px solid transparent;
        padding: 0.375rem 0.75rem;
        font-size: 0.875rem;
        line-height: 1.5;
        border-radius: 0.25rem;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }

    .sign-waiver-content {
        width: 75%;
    }
    
    .edit-button {
        padding: 0;
        font-size: 16px;
        color: var(--color1);
        font-weight: bold;
        text-decoration: underline;
    }

    .disabled {
        background-color: #e9ecef;
    }

    .error {
        color: #ff0000
    }
    .form-error {
        box-shadow: 0 1.5px 0px 0px #ff0000;
    }

    .full-responsive{
        @media only screen and (max-width: $md) {
            width:100%!important;
        }
    }
    // input[type="radio"]:checked {
    //     border-color: $base-color;
    // }

    // input[type="radio"]:checked:before {
    //     border-color: $base-color;
    // }

    // .checkmark {
    //     -webkit-appearance: none;
    //     width: 16px;
    //     height: 16px;
    //     border-radius: 50%;
    //     outline: none;
    //     border: 1px solid $base-color;
    // }

    // .checkmark:before {
    //     content: '';
    //     display: block;
    //     width: 60%;
    //     height: 60%;
    //     margin: 20% auto;
    //     border-radius: 50%;
    // }

    // .checkmark:checked:before {
    //     background: $base-color;
    //     width: 10px;
    //     height: 10px;
    //     margin: 2.1px;
    // }
    
    // .checkmark:checked {
    //     border-color:$base-color;
    // }
}

.signature-canvas-header{
    display: flex;
    justify-content: space-between;
    background: rgba(0, 0, 0, 0.03);
    padding: 12px;
    border-radius: 8px;
}
.signature-canvas-wrapper{
    overflow: hidden;    
    display: flex;
    justify-content: center;
}
.signature-canvas-wrapper canvas {
    border-bottom: 2px solid;
}