//********************** Variable **************//
:root {
    --color1: #262B4D;
    --color2: #262B4D;
    --color3: #262B4D;
    --color4: #FF6600;
}
// Base template Color
$base-color: var(--color2);
$secondary-color: var(--color1);
// Gray
$gray: var(--color4);
$lighter-gray: #d7d7d7;
$darker-gray: #7a7a7a;
$darkest-gray: #363636;
// White
$white: #fff;
// Green
$green: #00c88c;
$lighter-green: var(--color3);
$darker-green: #28a745;
$soft-green: #429782;
$available-green: #009682;
// Orange
$orange: #f4a230;
// Red
$red: #ff2f2f;
$darker-red: #bb0000;
// Font
$font-size-h1: 36px;
//*******************************************//
